body {
  margin: 0;
  padding: 0;
  font-family: "Helvetica Neue", Arial, Helvetica, Geneva, sans-serif !important;
}

@font-face {
    font-family: 'icomoon';
    src:    url(/static/media/icomoon.5d7a4220.eot);
    src:    url(/static/media/icomoon.5d7a4220.eot#iefix) format('embedded-opentype'),
        url(/static/media/icomoon.94e248d1.ttf) format('truetype'),
        url(/static/media/icomoon.0d0bb877.woff) format('woff'),
        url(/static/media/icomoon.e3b8f6da.svg#icomoon) format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    -webkit-font-feature-settings: normal;
            font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-stairs:before {
    content: "\E900";
}
.icon-toilet:before {
    content: "\E901";
}
.icon-coffee:before {
    content: "\E902";
}
.icon-tv:before {
    content: "\E907";
}
.icon-seat-business:before {
    content: "\E903";
}
.icon-seat-first:before {
    content: "\E904";
}
.icon-seat-economy:before {
    content: "\E905";
}
.icon-seat-crew:before {
    content: "\E906";
}
.icon-seat-driver:before {
    content: "\E909";
}


a.disabled {
  pointer-events: none !important;
  color: gray;
}

a.disabled:hover {
  cursor: not-allowed;
  color: gray;
}

.Loader {
  margin-top: 1rem;
}

.Passengers {
  margin-top: 1rem;
}

.ItinerarySearchResult {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.ItinerarySearchResult:hover {
  background-color: rgba(28, 4, 38, 0.8);
  color: #fff;
  cursor: pointer;
}

.Floor {
  padding: 50px 20px 30px 20px;
  background: url(/static/media/bus.77aa1ec6.svg);
  background-size: 100% 100%;
  background-clip: padding-box;
  margin: 10px auto;
  display: inline-block;
}

.Floor > div.row > div.col {
  padding: 0 5px;
}

.seat-number {
  font-family: 'Helvetica Neue', Arial, Helvetica, Geneva, sans-serif !important;
  font-size: 0.8em !important;
  font-weight: bold !important;
}

.taken {
  color: #dc3545;
}

.taken:hover {
  cursor: not-allowed;
}

.inProgress {
  color: #dcc035;
}

.inProgress:hover {
  cursor: not-allowed;
}

.callCenterReservation {
  color: #dc7b35;
}

.callCenterReservation:hover {
  cursor: not-allowed;
}

.selectedSeat {
  color: #28a745;
}

.specialPriceSeat {
  background-color: #ffd700;
  border: 2px solid #ffa500;
}

.specialPriceSeat .seat-number {
  font-weight: bold;
  color: #000;
}

.special-price-icon {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0.8em;
  color: red;
}

.text-center {
  text-align: center;
}

.rt-th > input[type='text'] {
  min-height: 38px;
}

.fixed-header {
  position: fixed !important;
  top: 0;
  background-color: #fff; /* Color de fondo de la cabecera fija */
  z-index: 1; /* Asegura que la cabecera fija esté por encima del contenido */
  /* Ajusta los estilos de la cabecera fija según tus necesidades */
}

.actionsContainer {
  display: flex;
  justify-content: space-evenly;
  margin-top: 5px;
}

.actionsContainer i {
  cursor: pointer;
}

.Receiver {
  margin-top: 2rem;
}

.summaryHeader {
  margin-top: 3rem;
}

.colorPurple {
  color: white;
  background-color: #4818a9;
}

.w10p {
  width: 10%;
}

.w20p {
  width: 20%;
}

.w40p {
  width: 40%;
}

.w60p {
  width: 60%;
}

.inputText {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  margin-bottom: 0.5rem;
}

.disabled {
  background-color: #e9ecef;
}

.tag {
  background-color: #f4f4f4;
  border: 1px solid #e9e9e9;
  padding: 2px 0 2px 2px;
  border-radius: 2px;
  display: inline-block;
}
.tag:focus-within {
  background-color: #e9e9e9;
  border-color: #a0a0a0;
}
.tag-remove {
  color: #a0a0a0;
  font-size: 75%;
  line-height: 100%;
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
}
.tag-remove.disabled,
.tag-remove.readOnly {
  cursor: not-allowed;
}
.tag-remove:focus {
  color: #3c3c3c;
}
.node > label {
  cursor: pointer;
  margin-left: 2px;
}
.tag-list {
  display: inline;
  padding: 0;
  margin: 0;
}
.tag-item {
  display: inline-block;
  margin: 4px;
}
.tag-item .search {
  border: none;
  border-bottom: 1px solid #ccc;
  outline: none;
}
.tag-item:last-child {
  margin-right: 4px;
}
.hide:not(.match-in-children) {
  display: none;
}
.react-dropdown-tree-select .dropdown {
  position: relative;
  display: table;
}
.react-dropdown-tree-select .dropdown .dropdown-trigger {
  padding: 4px;
  line-height: 20px;
  max-height: 200px;
  display: inline-block;
  overflow: auto;
  border: 1px solid #b9b9b9;
}
.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow {
  cursor: pointer;
}
.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.bottom:after {
  content: '\25BC';
  vertical-align: middle;
  color: #3c3c3c;
  margin-right: 2px;
}
.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.top:after {
  content: '\25B2';
  vertical-align: middle;
  color: #3c3c3c;
  margin-right: 2px;
}
.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.disabled {
  cursor: not-allowed;
}
.react-dropdown-tree-select
  .dropdown
  .dropdown-trigger.arrow.disabled.bottom:after {
  color: #b9b9b9;
}
.react-dropdown-tree-select .dropdown .dropdown-content {
  position: absolute;
  padding: 4px;
  z-index: 1;
  background: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);
}
.react-dropdown-tree-select .dropdown .dropdown-content ul {
  margin: 0;
  padding: 0;
}
.node {
  list-style: none;
  white-space: nowrap;
  padding: 4px;
}
.node.leaf.collapsed {
  display: none;
}
.node.disabled > * {
  color: gray;
  cursor: not-allowed;
}
.node.match-in-children.hide .node-label {
  opacity: 0.5;
}
.node.focused {
  background-color: #f4f4f4;
}
.toggle {
  white-space: pre;
  margin-right: 4px;
  cursor: pointer;
}
.toggle:after {
  content: ' ';
}
.toggle.collapsed:after {
  content: '+';
}
.toggle.expanded:after {
  content: '-';
}
.searchModeOn .toggle {
  display: none;
}
.checkbox-item,
.radio-item {
  vertical-align: middle;
  margin: 0 4px 0 0;
}
.checkbox-item.simple-select,
.radio-item.simple-select {
  display: none;
}

.infinite-scroll-component {
  max-height: 300px !important;
}

/*# sourceMappingURL=styles.css.map*/

.border-black,
table.all-borders th,
table.all-borders td {
  border: 1px solid black !important;
}

table .title {
  font-weight: 900 !important;
}

table th,
table td {
  vertical-align: middle !important;
}

.border-left-black {
  border-left: 1px solid black !important;
}

.border-right-black {
  border-right: 1px solid black !important;
}

.border-bottom-black {
  border-bottom: 1px solid black !important;
}

.border-top-black {
  border-top: 1px solid black !important;
}

table thead tr.shadow {
  box-shadow: 0 2px 15px 0 rgb(0 0 0 / 15%);
}

.bg-light-green {
  background-color: #d9ead3;
}

.bg-light-red {
  background-color: #f4cccc;
}

.label {
  text-align: right;
}

.summaryContainer {
  padding-left: 5rem;
}

.password {
  -webkit-text-security: disc;
}

.body-scrollable {
  max-height: 600px;
  overflow-x: hidden !important;
}

.pointer {
  cursor: pointer;
}

.board-column-width {
  min-width: 350px;
  width: 33%;
}

.board-column-element-height {
  max-height: calc(100vh - 320px);
}

.Main {
  padding-bottom: 1rem;
}

.password {
  -webkit-text-security: disc;
}

.comment-box {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  font-weight: bold;
  color: #fff;
}

.button-container {
  display: flex;
  justify-content: flex-end;
}

.comment-text {
  white-space: pre-wrap;
  word-break: break-word;
}

.mt-5 {
  margin-top: 2rem;
}

.comment-box {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  font-weight: bold;
  color: #fff;
}

.button-container {
  display: flex;
  justify-content: flex-end;
}

.comment-text {
  white-space: pre-wrap;
  word-break: break-word;
}

.mt-5 {
  margin-top: 2rem;
}

.grid-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow-x: auto;
    padding: 10px;
  }
  
  .grid-layout {
    display: grid;
    grid-gap: 5px;
    gap: 5px;
    max-width: 100%;
  }
  
  .grid-cell {
    width: 100%;
    height: 100%;
    min-width: 50px;
    min-height: 50px;
    border: 1px solid black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    font-size: 12px;
    text-align: center;
    background-color: #f5f5f5;
  }
  
  .grid-cell-disabled {
    background-color: #808080;
  }
  
  .grid-input {
    width: 50%;
    text-align: center;
    font-size: 12px;
  }
  
  .grid-input-disabled {
    background-color: #c0c0c0;
    color: #666;
  }
  
  .grid-input-enabled {
    background-color: #fff;
    color: #000;
  }
  
  .grid-button {
    position: absolute;
    top: 2px;
    right: 2px;
    border: none;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: bold;
    color: white;
  }
  
  .grid-button-disabled {
    background: green;
  }
  
  .grid-button-enabled {
    background: red;
  }
  
.shelf-grid-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: auto;
  padding: 5px;
  max-height: 100vh;
  max-width: 100%;
}

.shelf-grid {
  display: grid;
  grid-gap: 3px;
  gap: 3px;
  max-width: 100%;
  max-height: 100%;
}

.shelf-space {
  aspect-ratio: 1 / 1;
  width: 100%;
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  position: relative;
  font-size: 14px;
  text-align: center;
  box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.3);
  font-weight: bold;
  color: #000;
}

.shelf-space-hidden {
  aspect-ratio: 1 / 1;
  width: 100%;
  visibility: hidden;
}

/* logo that goes on the nav-bar header */
.header-logo {
  height: 40px;
}

/* header */
header nav.navbar {
  border-top: 2px solid #4818a9 !important;
  background-color: rgba(255, 255, 255, 0.85) !important;
  /*height: 52px !important;*/
  margin-bottom: 20px !important;
}

/* panels */
.card-header {
  background-color: rgba(28, 4, 38, 0.8) !important;
  border-color: rgba(28, 4, 38, 0.8) !important;
  color: white !important;
}

.card.module {
  border-color: #343a40 !important;
  text-align: center;
  height: 250px;
}

.card.module:hover {
  border-color: #343a40 !important;
  background-color: #343a40 !important;
  color: white !important;
}

.card.module:hover a {
  color: white !important;
}
/* cards with class primary */
.card.module.primary {
  border-color: #007bff !important;
  background-color: #007bff !important;
  color: #fff !important;
}

.card.module.primary:hover {
  border-color: #0062cc !important;
  background-color: #0069d9 !important;
  color: white !important;
}

/* links */
.silent,
.silent:hover,
.silent:focus {
  color: #343a40;
  text-decoration: none;
}

.silent.disabled,
.silent.disabled:hover,
.silent.disabled:focus {
  color: gray !important;
  text-decoration: none;
}

.clickable:hover {
  cursor: pointer;
}

.clickable.disabled:hover {
  cursor: not-allowed;
}

.FormButtonGroup {
  text-align: right;
  margin-top: 1rem;
}

.ItinerarySearch {
  margin-top: 1rem;
}

.flex {
  display: flex;
}

.row-reverse {
  flex-direction: row-reverse;
}

