.grid-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow-x: auto;
    padding: 10px;
  }
  
  .grid-layout {
    display: grid;
    gap: 5px;
    max-width: 100%;
  }
  
  .grid-cell {
    width: 100%;
    height: 100%;
    min-width: 50px;
    min-height: 50px;
    border: 1px solid black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    font-size: 12px;
    text-align: center;
    background-color: #f5f5f5;
  }
  
  .grid-cell-disabled {
    background-color: #808080;
  }
  
  .grid-input {
    width: 50%;
    text-align: center;
    font-size: 12px;
  }
  
  .grid-input-disabled {
    background-color: #c0c0c0;
    color: #666;
  }
  
  .grid-input-enabled {
    background-color: #fff;
    color: #000;
  }
  
  .grid-button {
    position: absolute;
    top: 2px;
    right: 2px;
    border: none;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: bold;
    color: white;
  }
  
  .grid-button-disabled {
    background: green;
  }
  
  .grid-button-enabled {
    background: red;
  }
  