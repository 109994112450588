.shelf-grid-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: auto;
  padding: 5px;
  max-height: 100vh;
  max-width: 100%;
}

.shelf-grid {
  display: grid;
  gap: 3px;
  max-width: 100%;
  max-height: 100%;
}

.shelf-space {
  aspect-ratio: 1 / 1;
  width: 100%;
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  position: relative;
  font-size: 14px;
  text-align: center;
  box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.3);
  font-weight: bold;
  color: #000;
}

.shelf-space-hidden {
  aspect-ratio: 1 / 1;
  width: 100%;
  visibility: hidden;
}
